import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import UploadComponent from "../../utility/UploadComponent";
import {quickEdit, updateEntityBranding} from "../../redux/reducers/entity/entityReducer";


export const Branding = ({isOpen, toggle}) => {
  const dispatch = useDispatch();
  const {loading, entity, rawEntity} = useSelector(state => ({
    loading: state.Entity.loading,
    entity: state.Entity.details,
    rawEntity: state.Entity.entity
  }))

  const [height, setHeight] = useState(rawEntity.logo_height)
  const [showLogoText, setShowLogoText] = useState(rawEntity.show_logo_text)
  const [logoText, setLogoText] = useState(rawEntity.logo_text)

  const handleUpload = (upload) => {
    let data = rawEntity;
    rawEntity.logo = upload.url
    dispatch(quickEdit(data))
  }

  const handleHeight = (value) => {
    let data = rawEntity;
    setHeight(value.target.value)
    rawEntity.logo_height = value.target.value
    dispatch(quickEdit(data))
  }

  const handleLogoText = (value) => {
    let data = rawEntity;
    setLogoText(value.target.value)
    rawEntity.logo_text = value.target.value
    dispatch(quickEdit(data))
  }

  const handleShowLogoText = (value) => {
    let data = rawEntity;
    setShowLogoText(!showLogoText)
    rawEntity.show_logo_text = showLogoText
    dispatch(quickEdit(data))
  }

  const handleLogoTextColor = (value) => {
    let data = rawEntity;
    rawEntity.logo_text_color = value.target.value
    dispatch(quickEdit(data))
  }


  return <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader>Branding</ModalHeader>
    <ModalBody>
      <Row>
        <Col sm={12}>
          <img className="img-thumbnail img-fluid" src={rawEntity.logo} alt="Logo"/>
        </Col>
        <Col>
          <UploadComponent onUpload={handleUpload}/>
        </Col>
      </Row>
      <FormGroup>
        <Label>Height ({rawEntity.logo_height} PX)</Label>
        <Input step={1} value={height} max={120} min={40} onChange={handleHeight} type="range"/>
      </FormGroup>
      <div>

        <FormGroup check inline>
          <Label check>
            {rawEntity.show_logo_text}
            <Input type="checkbox" defaultChecked={rawEntity.show_logo_text} checked={rawEntity.show_logo_text}
                   onChange={handleShowLogoText}/> Show Logo Text
          </Label>
        </FormGroup>
        <FormGroup hidden={!rawEntity.show_logo_text} className="mt-1">
          <Label> Logo Text </Label>
          <Input type="text" value={rawEntity.logo_text} onChange={handleLogoText}/>
        </FormGroup>
        <Row hidden={!rawEntity.show_logo_text}>
          <Col> <FormGroup hidden={!rawEntity.show_logo_text} className="mt-1">
            <Label> Logo Text Color </Label>
            <Input type="color" value={rawEntity.logo_text_color} onChange={handleLogoTextColor}/>
          </FormGroup>

          </Col>
          <Col>
            <h2 style={{color: rawEntity?.logo_text_color}} className="brand-text mt-3"> {rawEntity?.logo_text}</h2>
          </Col>
        </Row>


      </div>


    </ModalBody>
    <ModalFooter>
      <Button color="dark" onClick={toggle}>Close</Button>
      <Button color={"success"} onClick={() => {
        dispatch(updateEntityBranding(rawEntity))
        toggle()

      }}>Save Changes</Button>
    </ModalFooter>

  </Modal>


}
