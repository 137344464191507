import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
  Alert
} from 'reactstrap';
import Axios from "axios";
import {CatchError} from "../../../utility/General";

export default class ObligationApproveBreach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.breach.id,
      comments: this.props.breach.comments,
      penalty: this.props.breach.penalty,
      additional_penalty: this.props.breach.additional_penalty,
      title: this.props.breach.title,
      obligation: this.props.obligation,
      submission_deadline: this.props.breach.submission_deadline,
      modal: this.props.showBreachModal
    };
  }

  _approve = async () => {
    this.props.toggle();
    const data = {
      id: this.state.id,
      comments: this.state.comments,
      additional_penalty: this.state.additional_penalty,
    }
    let url = '/obligation/breach/approve';
    await Axios.post(url, data)
      .then(async () => {
        await this.props._get();
      })
      .catch(e => {
        CatchError(e);
        this.props.toggle();
      })
      .finally()
  }

  componentDidUpdate(prevProps) {
    if (this.props.breach !== prevProps.breach) {
      this.setState({
        id: this.props.breach.id,
        comments: this.props.breach.comments,
        penalty: this.props.breach.penalty,
        additional_penalty: this.props.breach.additional_penalty,
        title: this.props.breach.title,
        obligation: this.props.obligation,
        submission_deadline: this.props.breach.submission_deadline,
        modal: this.props.showBreachModal
      });
    }
  }




  render() {
    const { obligation} = this.props;
    const penalty_calc = () => {
      return parseFloat(obligation.non_compliance_penalty) + parseFloat(this.state.additional_penalty)

    }
    return (
      <Modal
        isOpen={this.props.showBreachModal}
        toggle={this.props.toggle}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.props.toggle} className="bg-danger">
          <strong>Approve Breach</strong> : <small>{this.state.title}</small>
        </ModalHeader>
        <ModalBody>
          <Alert color="danger">
            <div className="alert-heading">
              Penalty
            </div>
             <h4 className="text-center">KES {penalty_calc()} =</h4>
          </Alert>

          <Alert color="danger">
            <div className="alert-heading">
              Consequences
            </div>
            <p>{obligation.non_compliance_consequence}</p>
          </Alert>

          <FormGroup>
            <Label for="comments">Comment:</Label>
            <Input
              type={'textarea'}
              name={'comments'}
              value={this.state.comments}
              onChange={(v) => this.setState({comments: v.target.value})}
            />
          </FormGroup>
          <FormGroup>
            <Label for="additionalPenalty">Additional Penalty</Label>
            <Input
              type="number"
              id="additionalPenalty"
              onChange={val => {
                this.setState({additional_penalty: val.target.value})
              }}
              value={this.state.additional_penalty}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this._approve}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>

    )

  }
}
