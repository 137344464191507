import {
  Button,
  Col, Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Row} from "reactstrap";
import React from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Select from "react-select";
import Radio from "../../../components/@vuexy/radio/RadioVuexy";
import Flatpickr from "react-flatpickr";
import {
  ErrorHelper,
  HandleInputChange,
  HandleSelectOptions,
  PriorityColors,
  ValidateFormFields
} from "../../../utility/General";

export default class ObligationFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      id: this.props.elementId,
      loading: false,
      form: false,
      fields: false,
      errors: false,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.modal !== this.props.modal) this.set(this.state.id);

  }

  componentDidMount() {
    this.set(this.state.id);
  }

  set = id => {
    this.setState({loading: true})
    let url = 'app/compliance/obligation/set';
    let compliance_register = this.props.complianceRegisterId;
    let compliance_requirement = this.props.complianceRequirementId;
    let authority = this.props.authorityId;
    let params = {id, compliance_register, compliance_requirement, authority}
    axios.post(url, params)
      .then(res => this.setState({form: res.data, errors: res.data.errors, fields: res.data.fields}))
      .catch(this.error)
      .finally(() => this.setState({loading: false}))
  }

  post = () => {
    ValidateFormFields(this.state.fields, this.state.errors, ['title', 'authority', 'owners', 'description', 'compliance_requirement'])
      .then(res => this.setState({errors: res.errors, loading: true}));

    let url = "app/compliance/obligation/post";
    let data = this.state.fields;
    this.setState({loading: true});
    axios
      .post(url, data)
      .then(res => {
        this.toggleModal();
        this.props.callBack(res.data.id);
        // toast.success("Form submission successful");
      }).catch(this.error)
      .finally(() => this.setState({loading: false}))
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
    this.props.callBack();
  }
  handleInputChange = (label, element, required) => {
    // let results = HandleInputChange(label, element, required, this.state.fields, this.state.errors)
    // let fields = results.fields;
    // let errors = results.errors;
    this.setState(HandleInputChange(label, element, required, this.state.fields, this.state.errors))
  }


  error = (e) => {
    toast.error(e.statusText);
    // this.props.callBack()
    // this.toggleModal();
  }

  render() {
    const {form, fields, errors, loading} = this.state;
    return (
      <>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className="modal-centered modal-lg modal-centered modal-xl"
        >
          {form && <>
            <Form onSubmit={(e) => {
              e.preventDefault();
              this.post();
            }}>
              <ModalHeader toggle={this.toggleModal}>
                {form.fields.id ? `Edit ` : "Create Obligation"} {form.fields.title ? `: ${form.fields.title}` : null}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col sm={5}>
                    <FormGroup className="position-relative">
                      <Label> Title</Label>
                      <Input
                        name={'title'}
                        invalid={errors.title}
                        valid={errors.title === false}
                        type="text"
                        defaultValue={fields.title}
                        onChange={e => this.setState(HandleInputChange('title', e, true, fields, errors))}
                        placeholder="Obligation Title"/>
                      <ErrorHelper show={errors.title}/>
                    </FormGroup>

                    <FormGroup className="position-relative  ">
                      <Label>Submission Deadline</Label>
                      <Flatpickr
                        name={'submission_deadline'}
                        className="form-control"
                        value={new Date(fields.submission_deadline)}
                        onChange={e => this.handleInputChange('submission_deadline', e[0])}
                      />
                    </FormGroup>
                    <FormGroup className="position-relative  ">
                      <Label>Notification Days ({fields.notification_days})</Label>
                      <div>
                        {["5", "10", "20", "40"].map(record => <div className="d-inline-block mr-1">
                            <Radio
                              value={record}
                              label={`${record} Days`}
                              defaultChecked={fields.notification_days === record ? true : false}
                              onChange={e => this.handleInputChange('notification_days', e)}
                              name="notification_days"
                            />
                          </div>
                        )}
                      </div>


                    </FormGroup>
                    <FormGroup>
                      <Label>Priority</Label>
                      <div>
                        {form.enums.priority.map(record => <div className="d-inline-block mr-1">
                            <Radio
                              value={record}
                              label={record}
                              color={PriorityColors(record)}
                              defaultChecked={fields.priority === record}
                              onChange={e => this.handleInputChange('priority', e)}
                              name="priority"
                            />
                          </div>
                        )}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label>Repeat</Label>
                      <div>
                        {form.enums.repeat.map(record => <div className="d-inline-block mr-1">
                          <Radio
                            value={record}
                            label={record}
                            color="info"
                            name="repeat"
                            defaultChecked={fields.repeat === record}
                            onChange={(e) => this.handleInputChange('repeat', e)}
                          />
                        </div>)}
                      </div>
                    </FormGroup>
                    {fields.repeat === 'Periodic' && <FormGroup>
                      <Label>Frequency</Label>
                      <div>
                        {form.enums.frequency.map(record => <div className="d-inline-block mr-1">
                          <Radio
                            value={record}
                            label={record}
                            color="info"
                            name="frequency"
                            defaultChecked={fields.frequency === record}
                            onChange={(e) => this.handleInputChange('frequency', e)}
                          />
                        </div>)}
                      </div>
                    </FormGroup>}
                    <FormGroup>
                      <Label>Activity</Label>
                      <div>
                        {form.enums.activity.map(record => <div className="d-inline-block mr-1">
                          <Radio
                            value={record}
                            label={record}
                            color="info"
                            name="activity"
                            defaultChecked={fields.activity === record}
                            onChange={(e) => this.handleInputChange('activity', e)}
                          />
                        </div>)}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <FormGroup className="position-relative">
                          <Label>Departments</Label>
                          <Select
                            className="React"
                            classNamePrefix="select"
                            defaultValue={fields.department}
                            name="compliance_requirement"
                            onChange={e => this.setState(HandleInputChange('department', e, true, fields, errors))}
                            options={HandleSelectOptions(form.departments, 'title', 'id')}
                          />
                          <ErrorHelper show={errors.department}/>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="position-relative">
                          <Label>Compliance Requirement</Label>
                          <Select
                            className="React"
                            classNamePrefix="select"
                            defaultValue={fields.compliance_requirement}
                            name="compliance_requirement"
                            onChange={e => this.setState(HandleInputChange('compliance_requirement', e, true, fields, errors))}
                            options={HandleSelectOptions(form.compliance_requirements, 'title', 'id')}
                          />
                          <ErrorHelper show={errors.compliance_requirement}/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="position-relative">
                          <Label>Authority</Label>
                          <Select
                            className="React"
                            classNamePrefix="select"
                            defaultValue={fields.authority}
                            name="authority"
                            onChange={e => this.setState(HandleInputChange('authority', e, true, fields, errors))}
                            options={form.authorities}
                          />
                          <ErrorHelper show={errors.authority}/>
                        </FormGroup></Col>
                      <Col>
                        <FormGroup className="position-relative">
                          <Label>Owners</Label>
                          <Select
                            defaultValue={form.fields.owners}
                            isMulti
                            name="owners"
                            options={form.users}
                            onChange={e => this.setState(HandleInputChange('owners', e, true, fields, errors))}
                            className="React"
                            classNamePrefix="select"
                          />
                          <ErrorHelper show={errors.owners}/>
                        </FormGroup></Col>
                    </Row>
                    <FormGroup className="position-relative">
                      <Label>Non-Compliance Penalty</Label>
                      <Input
                        type="number"
                        name="non_compliance_penalty"
                        id="exampleText"
                        onChange={e => this.handleInputChange('non_compliance_penalty', e)}
                        defaultValue={fields.non_compliance_penalty}
                      />
                    </FormGroup>
                    <FormGroup className="position-relative">
                      <Label>Non-Compliance Consequences</Label>
                      <Input
                        type="textarea"
                        name="non_compliance_consequence"
                        id="exampleText"
                        rows="2"
                        placeholder="Describe Obligation"
                        onChange={e => this.handleInputChange('non_compliance_consequence', e)}
                        defaultValue={fields.non_compliance_consequence}
                      />
                    </FormGroup>
                    <FormGroup className="position-relative">
                      <Label>Description</Label>
                      <Input
                        invalid={errors.title}
                        valid={errors.title === false}
                        type="textarea"
                        name="description"
                        id="exampleText"
                        rows="7"
                        placeholder="Describe Obligation"
                        onChange={e => this.setState(HandleInputChange('description', e, true, fields, errors))}
                        defaultValue={fields.description}
                      />
                      <ErrorHelper show={errors.description}/>
                    </FormGroup>
                  </Col>
                </Row>
                {/*{JSON.stringify(fields)}*/}
              </ModalBody>

              {!loading && <ModalFooter>
                <p className="float-left text-left"><small>Please fill in all the (*) fields</small></p>

                <Button color="warning" outline onClick={this.toggleModal}>
                  Cancel
                </Button>
                <Button type={'submit'} color="success" outline>
                  Submit
                </Button>
              </ModalFooter>}
            </Form>
          </>}
        </Modal>
      </>
    );
  }
}
