import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Datatable from "react-data-table-component";
import {departmentDelete, departmentGet} from "../../redux/departments/departmentRegister";
import {Button, ButtonGroup, Card, CardHeader, CardTitle} from "reactstrap";
import {Edit, Plus, RefreshCw, Trash} from "react-feather";
import DepartmentFormModal from "./DepartmentFormModal";


export const Departments = (props) => {
  const {departments, loading} = useSelector((state) => ({
    departments: state.Department.departments,
    loading: state.Department.loading,
  }))
  const dispatch = useDispatch()
  const [form, setForm] = useState(false);
  const [department, setDepartment] = useState(false)
  const columns = [
    {name: "Department", selector: ({title}) => <strong>{title}</strong>},
    {name: "Head Of Department", selector: row => row.head_of_department?.name},
    {
      name: "Obligations",
      center: true,
      selector: row => <span className="font-medium-2 font-weight-bold">{row.obligations}</span>
    },
    {
      name: "Penalties (Ksh)", right: true, sortable: true,
      selector: ({total_non_compliance_penalty}) => parseInt(total_non_compliance_penalty),

      cell: row => <span
        className={`font-medium-1 font-weight-bold ${parseInt(row.total_non_compliance_penalty) > 0 ? `text-danger` : `text-success`}`}>{row.total_non_compliance_penalty} /=</span>
    },
    {
      right: true,
      name: "", cell: row => <ButtonGroup>
        {row.obligations === 0 &&
          <Button size="sm" color="danger" outline onClick={() => dispatch(departmentDelete({id: row.id}, get))}><Trash
            size={12}/></Button>}
        <Button size="sm" color="success" outline onClick={() => toggleForm(row.id)}><Edit size={12}/></Button>
      </ButtonGroup>
    }
  ]

  const toggleForm = (department) => {
    setDepartment(department)
    setForm(!form)
  }

  const get = () => dispatch(departmentGet())

  useEffect(get, []);

  return <Card>
    <CardHeader>
      <CardTitle>Departments</CardTitle>
      <ButtonGroup>
        <Button size={"sm"} outline color="info" onClick={get}><RefreshCw size={14}/></Button>
        <Button size={"sm"} outline color="success" onClick={() => toggleForm(0)}><Plus size={14}/> Add</Button>
      </ButtonGroup>
    </CardHeader>
    <hr/>
    <Datatable noHeader pagination columns={columns} data={departments}/>
    <DepartmentFormModal callBack={() => {
      toggleForm()
      get();
    }} toggle={toggleForm} elementId={department} isOpen={form}/>
  </Card>


}
