import React from 'react';
import {Col, CustomInput, FormGroup, Label, Progress, Row} from "reactstrap";
import Axios from "axios";
import DataTable from "react-data-table-component";
import {CatchError} from "./General";

export default class UploadComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      file: false,
      files: false,
      status: 'info'
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }


  converter = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  upload = event => {
    this.props.setLoading && this.props.setLoading();
    this.setState({progress: 0})
    const setProgress = progress => this.setState({progress})
    const config = {
      onUploadProgress: function (progressEvent) {
        let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setProgress(progress);
      }
    }
    const formData = new FormData();
    if (!event.target.files[0]) return console.log('Nothing to upload')

    formData.append('file', event.target.files[0]);

    let url = 'app/upload/file'
    Axios.post(url, formData, config).then((res) => {
      let r = res.data;
      let status = 'success';
      (Array.isArray(res.data)) ? this.setState({files: r, status}) : this.setState({file: [r], status});
      this.props.onUpload(res.data)
    }).catch((e) => CatchError(e))
      .finally(() => this.props.setLoading && this.props.setLoading())
  }

  render() {
    const {progress, files, status} = this.state;
    const columns = [
      {
        name: "Title",
        selector: "title",
        sortable: true
      },
      {
        name: "Size",
        selector: "size",
        sortable: true,
        cell: (row) => this.converter(row.size)
      },
      {
        name: "Type",
        selector: "type",
        sortable: true
      },
    ]
    return (
      <>
        <FormGroup>
          <Label for="customFile">{this.props.name ? this.props.name : 'Upload File'}</Label>
          <CustomInput
            autoFocus={this.props.focus}
            type="file"
            id="exampleCustomFileBrowser"
            name="customFile"
            onChange={this.upload}
          />
        </FormGroup>
        {(progress > 0 && progress < 100) && (<Row>
          <Col xs={9}>
            <div className="m-50"><Progress color={status} value={progress}/></div>
          </Col>
          <Col xs={3}>
            <>{progress < 100 ? `${progress} %` : `Complete`} </>
          </Col>
        </Row>)}
        {files && <DataTable columns={columns} data={files}/>}
      </>)
  }


}
