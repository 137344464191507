import React, {useState} from "react";
import {Card, CardBody, CardHeader, CardTitle, Table, Button,  ButtonGroup, Alert, Input} from "reactstrap";
import {BadgeStatus} from "../../../utility/General";
import {
  AlertTriangle,
  ChevronDown, Edit,
  ThumbsUp, Trash, X
} from "react-feather";
import UncontrolledCollapse from "reactstrap/es/UncontrolledCollapse";
import ObligationComplyForm from "./ObligationComplyForm";
import {Can} from "../../../utility/Can";
import DisplayDownloadDocument from "../../../utility/DisplayDownloadDocument";
import SweetAlert from "react-bootstrap-sweetalert";
import {useDispatch} from "react-redux";
import {
  approveComplyObligation,
  deleteComplyObligation,
  rejectComplyObligation
} from "../../../redux/actions/obligationActions";

const ObligationComplies = (props) => {

  const dispatch = useDispatch();


  const [showComplyFormModal, setShowComplyFormModal] = useState(false);
  const [comply, setComply] = useState(false);
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [rejection_reason, setRejection_reason] = useState('');
  const [complyID, setComplyID] = useState(false)

  const _toggleModal = () => setShowComplyFormModal(!showComplyFormModal)

  const _approve = (id) => dispatch(approveComplyObligation(id, props.callBack))

  const _reject = (id) => dispatch(rejectComplyObligation(id, rejection_reason, props.callBack))

  const _delete = (id) => dispatch(deleteComplyObligation(id, props.callBack))

  const {obligation, complies, callBack} = props;

  return props.complies.length > 0 && <Card className="border mt-3">
    {obligation && <>
      <CardHeader>
        <CardTitle>Complies</CardTitle>
      </CardHeader>
      <CardBody>
        <div className="vx-collapse collapse-bordered">
          {complies.map(record => <Card key={`record-${record.id}`}>
              <CardHeader id={`record-${record.id}`}>
                <CardTitle className="lead collapse-title collapsed">
                  {record.source === 'delete' &&
                  <span><AlertTriangle size={10} className="text-warning"/>  </span>}
                  {record.title}
                </CardTitle>
                <BadgeStatus status={record.status}/>
                <ChevronDown size={15} className="collapse-icon"/>
              </CardHeader>
              <UncontrolledCollapse toggler={`#record-${record.id}`}>
                <CardBody>
                  {record.status === 'Rejected' &&
                  <Alert color="danger">
                    <div className="alert-danger p-25 pl-1"><AlertTriangle size={16}/> Rejected</div>
                    <p dangerouslySetInnerHTML={{__html: record.rejection_reason}} className="p-1"></p>
                  </Alert>}
                  <Table className="table-condensed" size={"sm"}>
                    <tbody>
                    <tr>
                      <th>Submission Deadline</th>
                      <td>{record.submission_deadline}</td>
                    </tr>
                    <tr>
                      <th>Submission Item</th>
                      <td>
                        <DisplayDownloadDocument
                          title={record.upload_title}
                          size={14}
                          filesize={record.upload_filesize}
                          id={record.upload_id}/>

                      </td>
                    </tr>
                    <tr>
                      <th>Comment</th>
                      <td>{record.comment}</td>
                    </tr>
                    {obligation.status === 'Active' &&
                    <tr>
                      <td colSpan={2}>
                        <ButtonGroup>
                          {Can('Obligation Comply Approval') && record.status === 'Pending' &&
                          <Button size={'sm'} onClick={() => _approve(record.id)} color={'flat-success'}>
                            <ThumbsUp size={14}/> Approve
                          </Button>}

                          {Can('Obligation Comply Approval') && record.status === 'Pending' &&
                          <Button onClick={() => {
                            setShowRejectionReason(true);
                            setRejection_reason(comply.rejection_reason)
                            setComply(record)
                            setComplyID(record.id)

                          }}

                                  color={'flat-danger'}>
                            <X size={14}/> Reject
                          </Button>}

                          {Can('Obligation Comply') && record.status === 'Pending' &&
                          <Button size={'sm'}
                                  onClick={() => {
                                    setShowComplyFormModal(true);
                                    setComplyID(record.id)
                                  }}
                                  color={'flat-primary'}>
                            <Edit size={14}/> Edit
                          </Button>}

                          {Can('Obligation Comply Delete') && record.status === 'Rejected' &&
                          <Button size={'sm'} onClick={() => _delete(record.id)} block
                                  color={'flat-danger'}>
                            <Trash size={14}/> Delete
                          </Button>}
                        </ButtonGroup>
                      </td>
                    </tr>}
                    </tbody>
                  </Table>
                  {/*{JSON.stringify(obligation)}*/}
                </CardBody>
              </UncontrolledCollapse>
            </Card>
          )}
        </div>
      </CardBody>
    </>
    }
    <ObligationComplyForm
      obligation={obligation && obligation.id}
      complyID={complyID}
      modal={showComplyFormModal}
      toggle={_toggleModal}
      _get={() => {
        callBack && callBack();
        setShowComplyFormModal(false)
      }}
    />
    <SweetAlert
      title="Reasons for your rejection "
      show={showRejectionReason}
      placeHolder="Why?"
      onConfirm={() => {
        setShowRejectionReason(false);
        _reject(complyID);
      }}
      showCancel
      cancelBtnBsStyle="primary"
      confirmBtnBsStyle={'danger'}
      confirmBtnText="Reject"
      cancelBtnText="Cancel"
      onCancel={() => {
        setRejection_reason('')
        setShowRejectionReason(false)
        setComply(false)
      }}
    >
      <Input
        type={'textarea'}
        placeholder={'Why'}
        rows={5}
        onChange={rejection_reason => setRejection_reason(rejection_reason.target.value)}/>
    </SweetAlert>
  </Card>

}

export default ObligationComplies
