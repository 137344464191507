// import axios from "axios";
import {
  OBLIGATION_ACTION_FAILURE,
  OBLIGATION_ACTION_REQUEST,
  OBLIGATION_ACTION_SUCCESS, OBLIGATION_BILL_GET_FAILURE, OBLIGATION_BILL_GET_REQUEST, OBLIGATION_BILL_GET_SUCCESS,
  OBLIGATION_DELETE_FAILURE,
  OBLIGATION_DELETE_REQUEST,
  OBLIGATION_DELETE_SUCCESS,
  OBLIGATION_FIND_FAILURE,
  OBLIGATION_FIND_REQUEST,
  OBLIGATION_FIND_SUCCESS,
  OBLIGATION_GET_FAILURE,
  OBLIGATION_GET_REQUEST,
  OBLIGATION_GET_SUCCESS
} from "../types/obligationTypes";
import Axios from "axios";

export const getObligations = () => {
  return dispatch => {
    dispatch({type: OBLIGATION_GET_REQUEST})
    Axios.get('app/compliance/obligation/get')
      .then(res => dispatch({type: OBLIGATION_GET_SUCCESS, payload: res.data.data}))
      .catch(error => dispatch({type: OBLIGATION_GET_FAILURE, payload: error}))
  }
}
export const getBillObligations = () => {
  return dispatch => {
    dispatch({type: OBLIGATION_BILL_GET_REQUEST})
    Axios.get('application/obligations')
      .then(res => dispatch({type: OBLIGATION_BILL_GET_SUCCESS, payload: res.data}))
      .catch(error => dispatch({type: OBLIGATION_BILL_GET_FAILURE, payload: error}))
  }
}


export const importObligations = () => {
  return dispatch => {
    dispatch({type: OBLIGATION_GET_REQUEST})
    Axios.get('update')
      .finally(() => dispatch(getObligations()))
  }
}

export const allObligations = () => {
  return dispatch => {
    dispatch({type: OBLIGATION_GET_REQUEST})
    Axios.get('app/compliance/obligation/all')
      .then(res => dispatch({type: OBLIGATION_GET_SUCCESS, payload: res.data.data}))
      .catch(error => dispatch({type: OBLIGATION_GET_FAILURE, payload: error}))
  }
}

export const findObligation = (id) => {
  return dispatch => {
    dispatch({type: OBLIGATION_FIND_REQUEST, payload: id})
    Axios.get(`/app/compliance/obligation/find`, {params: {id}})
      .then(res => dispatch({type: OBLIGATION_FIND_SUCCESS, payload: res.data}))
      .catch(e => dispatch({type: OBLIGATION_FIND_FAILURE, payload: e}))
  }
}

export const deleteObligation = (id, callBack) => {
  return dispatch => {
    let url = 'app/compliance/obligation/delete';
    let data = {id}
    dispatch({type: OBLIGATION_DELETE_REQUEST})
    Axios.post(url, data)
      .then(() => {
        dispatch({type: OBLIGATION_DELETE_SUCCESS})
        dispatch(getObligations());
        callBack && callBack()
      })
      .catch(error => dispatch({type: OBLIGATION_DELETE_FAILURE}))
  }
}

export const activationObligation = (id) => {
  return dispatch => {
    dispatch({type: OBLIGATION_ACTION_REQUEST})
    const url = 'app/compliance/obligation/activation';
    const data = {id}
    Axios.post(url, data)
      .then(() => {
        dispatch({type: OBLIGATION_ACTION_SUCCESS})
        dispatch(findObligation(id))
        dispatch(getObligations())
      })
      .catch(error => dispatch({type: OBLIGATION_ACTION_FAILURE}))
      .finally(() => dispatch(getObligations()))
  }
}

const obligationAction = (dispatch, id, url, data, callBack) => {
  dispatch({type: OBLIGATION_ACTION_REQUEST})
  Axios.post(url, data)
    .then((res) => {
      dispatch({type: OBLIGATION_ACTION_SUCCESS})
      if (!callBack) {
        dispatch(findObligation(res.data.obligation))
        dispatch(getObligations())
      } else {
        console.log("Callback", typeof callBack)
        callBack()
      }
    })
    .catch(error => dispatch({type: OBLIGATION_ACTION_FAILURE, payload: error}))
}

export const deleteComplyObligation = (id, callBack) => {
  return dispatch => {
    dispatch({type: OBLIGATION_ACTION_REQUEST})
    const url = 'app/compliance/obligation/comply/delete';
    const data = {id}
    obligationAction(dispatch, id, url, data, callBack);
  }
}

export const approveComplyObligation = (id, callBack) => {
  return dispatch => {
    dispatch({type: OBLIGATION_ACTION_REQUEST})
    const url = 'app/compliance/obligation/comply/approve';
    const data = {id}
    obligationAction(dispatch, id, url, data, callBack)
  }
}

export const rejectComplyObligation = (id, rejection_reason, callBack) => {
  return dispatch => {
    dispatch({type: OBLIGATION_ACTION_REQUEST})
    const url = 'app/compliance/obligation/comply/reject';
    const data = {id, rejection_reason}
    obligationAction(dispatch, id, url, data, callBack)

  }
}

export const approveBreachObligation = (id, callBack) => {
  const url = 'app/compliance/obligation/breach/approve';
  const data = {id}
  return dispatch => obligationAction(dispatch, id, url, data, callBack)
}

export const rejectBreachObligation = (id, rejection_reason, callBack) => {
  const url = 'app/compliance/obligation/breach/reject';
  const data = {id, rejection_reason}
  return dispatch => obligationAction(dispatch, id, url, data, callBack)
}

export const deleteBreachObligation = (id, callBack) => {
  const url = 'app/compliance/obligation/breach/delete';
  const data = {id}
  return dispatch => obligationAction(dispatch, id, url, data, callBack)
}

export const rejectAllBreachObligation = (obligationBreaches, rejection_reason, callBack) => {
  return dispatch => {
    let breachesIds = []
    obligationBreaches.map(breach => (breach.status === 'Pending') && breachesIds.push(breach.id))
    const data = {breaches: breachesIds, rejection_reason}
    const url = 'app/compliance/obligation/breach/rejects';
    const obligationID = obligationBreaches[0].obligation;
    dispatch({type: OBLIGATION_ACTION_REQUEST})
    return Axios.post(url, data)
      .then(res => {
        dispatch({type: OBLIGATION_ACTION_SUCCESS})
        if (!callBack) {
          dispatch(findObligation(obligationID))
          dispatch(getObligations())
        } else {
          callBack()
        }
      })
      .catch(e => dispatch({type: OBLIGATION_ACTION_FAILURE, payload: e}))

  }
}

export const deleteAllBreachObligation = (obligationBreaches, callBack) => {
  return dispatch => {
    let breachesIds = []
    obligationBreaches.map(breach => (breach.status === 'Rejected') && breachesIds.push(breach.id))
    const data = {breaches: breachesIds}
    const url = 'app/compliance/obligation/breach/delete/all';
    const obligationID = obligationBreaches[0].obligation;
    dispatch({type: OBLIGATION_ACTION_REQUEST})
    return Axios.post(url, data)
      .then(res => {
        dispatch({type: OBLIGATION_ACTION_SUCCESS})
        if (!callBack) {
          dispatch(findObligation(obligationID))
          dispatch(getObligations())
        } else {
          callBack()
        }
      })
      .catch(e => dispatch({type: OBLIGATION_ACTION_FAILURE, payload: e}))

  }
}
