import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {
  quickEdit,
  setEntityFrom,
  updateEntityClassification,
  updateEntityProfile
} from "../../redux/reducers/entity/entityReducer";
import {Pre} from "../../components/preFormat";
import Flatpickr from "react-flatpickr";
import Select from "react-select";


export const Clasification = ({isOpen, toggle}) => {
  const dispatch = useDispatch();
  const {loading, entity, rawEntity, form} = useSelector(state => ({
    loading: state.Entity.loading,
    entity: state.Entity.entity,
    form: state.Entity.form

  }))

  const [services, setServices] = useState(entity.services);
  const [location, setLocation] = useState(entity.location);
  const [company_type, setCompanyTypes] = useState(entity.company_type);



  useEffect(() => {
    isOpen && dispatch(setEntityFrom())
  }, [isOpen]);

  useEffect(() => {
   if(isOpen){
     let data = entity;
     entity.services = services
     entity.company_type = company_type
     entity.location = location
     dispatch(quickEdit(data))
   }
  }, [services, location, company_type])




  return <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader>Classification</ModalHeader>
    <ModalBody>
      <FormGroup>
        <Label>Location </Label>
        <Select
          closeMenuOnSelect={false}
          defaultValue={entity.location}
          onChange={setLocation}
          options={form?.locations}
          className="React"
          value={location}
          classNamePrefix="select"
        />
      </FormGroup>

      <FormGroup>
        <Label>Company Type </Label>
        <Select
          closeMenuOnSelect={false}
          defaultValue={entity.company_type}
          onChange={setCompanyTypes}
          value={company_type}
          options={form?.types}
          className="React"
          classNamePrefix="select"
        />
      </FormGroup>


      <FormGroup>
        <Label>Services </Label>
        <Select
          closeMenuOnSelect={false}
          defaultValue={entity.services}
          isMulti
          onChange={setServices}
          options={form?.services}
          value={services}
          className="React"
          classNamePrefix="select"
        />

      </FormGroup>




    </ModalBody>
    <ModalFooter>
      <Button color="dark" onClick={toggle}>Close</Button>
      <Button color={"success"} onClick={() => {
        dispatch(updateEntityClassification(entity))
        toggle()

      }}>Save Changes</Button>
    </ModalFooter>

  </Modal>


}
