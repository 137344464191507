import React from 'react';
import {CatchError, sizeCalc} from "./General";
import { DownloadCloud} from "react-feather";
import axios from 'axios';
import {toast} from "react-toastify";
import {Spinner, UncontrolledTooltip} from 'reactstrap';
import Chip from "../components/@vuexy/chips/ChipComponent";
import {Link} from "react-router-dom";

export default class DisplayDownloadDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  fetchFile = (id) => {
    if (this.state.loading) return toast.warn("Already Downloading the document, please wait")
    this.setState({loading: true})
    axios({
      url: "app/files/download",
      method: "GET",
      params: {id},
      // headers: headers,
      responseType: "blob" // important
    }).then(response => {

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${this.props.title}`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

    }).catch(CatchError)
      .finally(() => this.setState({loading: false}));
  }

  render() {
    const {id, title, filesize} = this.props;
    const {loading} = this.state;
    if (!id) return false;
    const elementId = `file-tooltip-${id}`
    const max = 15;
    return <><Link
      key={id}
      id={`file-tooltip-${id}`}
      className="cursor-pointer"
      onClick={() => this.fetchFile(id)}
      // title={filesize && sizeCalc(filesize, 0)}
    >
      <Chip avatarColor="info" className="mr-25"
            text={` ${title && title.length > max ? (title.substr(0, max)
              + '...' + title.substr(title.length - 5, title.length)) : title} (` + sizeCalc(filesize, 0) + `)`}
            avatarIcon={loading ? <Spinner size={'sm'}/> : <DownloadCloud size={14}/>}/>
    </Link>
      <UncontrolledTooltip
        placement="top"
        target={elementId}
      >
        {title} {id}
      </UncontrolledTooltip>
    </>
  }
}
