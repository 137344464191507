import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import Axios from "axios";
import {CatchError, DisplayDocument} from "../../../utility/General";
import UploadComponent from "../../../utility/UploadComponent";


export default class ObligationComplyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      obligation: this.props.obligation,
      modal: this.props.modal,
      form: false,
      id: this.props.complyID,
      file: null,
      title: null,
      comment: null,
      submission_deadline: null,
      compliance_action: null,
      attachment: null,
      uploading: false,
    };
  }

  componentDidMount = () => this._set()

  componentDidUpdate = (prevProps) => {
    if (prevProps.modal !== this.props.modal && this.props.modal) this._set()
  }

  _set = async () => {
    this.setState({loading: true, form: false})
    let url = 'app/compliance/obligation/comply/set';
    // if (!this.props.obligation || !this.props.complyID) return false;
    let data = {id: this.props.complyID, obligation: this.props.obligation}

    await Axios.post(url, data)
      .then(res => {
        this.setState({
          obligation: res.data.obligation,
          form: res.data,
          id: res.data.fields.id,
          title: res.data.fields.title,
          comment: res.data.fields.comment,
          submission_deadline: res.data.fields.submission_deadline,
          attachment: res.data.fields.attachment
        })
      })
      .catch(CatchError)
      .finally(() => this.setState({loading: false}))
  }

  upload = attachment => this.setState({attachment})


  _post = async () => {
    this.setState({loading: false})
    this.props.toggle();
    const data = {
      id: this.state.id,
      obligation: this.state.obligation.id,
      title: this.state.title,
      comment: this.state.comment,
      submission_deadline: this.state.submission_deadline,
      file: this.state.file,
      attachment: this.state.attachment
    }
    let url = 'app/compliance/obligation/comply/post';
    await Axios.post(url, data)
      .then(() => this.props._get ? this.props._get() : null)
      .catch(e => {
          if (this.props._get) this.props._get()
          CatchError(e);
        }
      ).finally(() => {
        if (this.props._get) this.props._get()
        this.setState({loading: false})
      })
  }


  render() {
    const {loading, uploading, obligation, attachment} = this.state;
    return (
      <Modal
        isOpen={this.props.modal}
        toggle={this.props.toggle}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this.props.toggle}>
          {!loading && <> <strong>Comply
            to</strong> : <small>{this.state.obligation ? this.state.obligation.title : false}</small></>}
          {loading && "Loading"}
        </ModalHeader>
        <ModalBody>
          {this.state.form && <>
            <FormGroup>
              <Label for="title">Title:</Label>
              <Input
                readOnly
                type="text"
                id="title"
                placeholder="Comply Title"
                value={this.state.title}
                // onChange={(v) => this.setState({title: v.target.value})}
              />
            </FormGroup>

            <FormGroup>
              <Label for="comment">Comment:</Label>
              <Input
                type={'textarea'}
                name={'comment'}
                value={this.state.comment}
                onChange={(v) => this.setState({comment: v.target.value})}
              />
            </FormGroup>
            <FormGroup>
              <Label for="comment">Submission Deadline:</Label>
              <Input
                type={'text'}
                name={'comment'}
                value={this.state.submission_deadline}
                onChange={(v) => this.setState({submission_deadline: v.target.value})}
              />
            </FormGroup>
            {obligation.activity === 'Document Submission' &&
            <UploadComponent name={'Upload File'}
                             setLoading={() => this.setState({uploading: !uploading})}
                             onUpload={this.upload}/>}
            {attachment && <DisplayDocument
              title={attachment.title} size={14}
              filesize={attachment.filesize}
              id={attachment.id}/>}
          </>}
        </ModalBody>
        <ModalFooter>
          {(!uploading || !loading) && <Button color="primary" onClick={() => this._post()}> Submit </Button>}
        </ModalFooter>
      </Modal>

    )

  }
}
