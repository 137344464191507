import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {quickEdit, updateEntityProfile} from "../../redux/reducers/entity/entityReducer";
import {Pre} from "../../components/preFormat";
import Flatpickr from "react-flatpickr";


export const Profile = ({isOpen, toggle}) => {
  const dispatch = useDispatch();
  const {loading, entity, rawEntity} = useSelector(state => ({
    loading: state.Entity.loading,
    entity: state.Entity.entity,
  }))

  const handleChange = (label, value) => {
    let data = entity;
    entity[label] = value
    dispatch(quickEdit(data))
  }


  return <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader>Branding</ModalHeader>
    <ModalBody>
      <FormGroup className="mt-1">
        <Label> Company Name </Label>
        <Input type="text" value={entity.title} onChange={e => handleChange('title', e.target.value)} />
      </FormGroup>
      <FormGroup className="mt-1">
        <Label> Domain </Label>
        <Input type="text" value={entity.domain} onChange={e => handleChange('domain', e.target.value)} />
      </FormGroup>
      <FormGroup className="mt-1">
        <Label> Financial Year </Label>
        <Flatpickr
          className="form-control"
          value={entity.financial_year}
          onChange={date => handleChange('financial_year', date[0])}
        />
      </FormGroup>
    </ModalBody>
    <ModalFooter>
      <Button color="dark" onClick={toggle}>Close</Button>
      <Button color={"success"} onClick={() => {
        dispatch(updateEntityProfile(entity))
        toggle()

      }}>Save Changes</Button>
    </ModalFooter>

  </Modal>


}
