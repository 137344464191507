import React, {useState} from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  ButtonGroup,
  Input,
  Alert,
  Spinner
} from "reactstrap";
import {BadgeStatus,} from "../../../utility/General";
import {AlertTriangle, ChevronDown, Trash2, XCircle} from "react-feather";
import UncontrolledCollapse from "reactstrap/es/UncontrolledCollapse";
import ObligationApproveBreach from "./ObligationApproveBreach";
import {Can} from "../../../utility/Can";
import SweetAlert from "react-bootstrap-sweetalert";
import {useDispatch} from "react-redux";
import {
  approveBreachObligation, deleteAllBreachObligation,
  deleteBreachObligation, rejectAllBreachObligation,
  rejectBreachObligation
} from "../../../redux/actions/obligationActions";

const ObligationBreaches = (props) => {
  const dispatch = useDispatch();

  const {loading, breaches, obligation, callBack} = props;

  const [showBreachModal, setShowBreachModal] = useState(false)
  const [breach, setBreach] = useState(false)
  const [showRejectionReason, setShowRejectionReason] = useState(false)
  const [showRejectAllReason, setShowRejectAllReason] = useState(false)
  const [rejection_reason, setRejection_reason] = useState(false)

  const _toggleModal = () => setShowBreachModal(!showBreachModal);
  const _approve = (id) => dispatch(approveBreachObligation(id, callBack))
  const _delete = (id) => dispatch(deleteBreachObligation(id, callBack))
  const _reject = (id, rejection_reason) => {
    setShowRejectionReason(false)
    dispatch(rejectBreachObligation(id, rejection_reason, callBack))
  }

  const _rejectAll = () => {
    setShowRejectAllReason(false);
    const {breaches} = props;
    dispatch(rejectAllBreachObligation(breaches, rejection_reason, callBack))
  }
  const _deleteAll = () => {
    setShowRejectAllReason(false);
    const {breaches} = props;
    dispatch(deleteAllBreachObligation(breaches, callBack))
  }

  const filterByStatusPending = breach => (breach.status === 'Pending') ? true : false

  const filterByStatusRejected = breach => (breach.status === 'Rejected') ? true : false

  return (<>{breaches.length > 0 && <Card className="border mt-3">
    <CardHeader>
      <CardTitle>Breaches</CardTitle>
      {!loading && false && <ButtonGroup>
        {(Can('Obligation Breach Approval') && breaches.filter(filterByStatusPending).length > 1) &&
        <Button size={'sm'} outline color={'success'} onClick={_rejectAll}>

          Reject All
        </Button>}
        {(Can('Obligation Breach Delete') && breaches.filter(filterByStatusRejected).length > 1) &&
        <Button size={'sm'} outline color={'danger'} onClick={_deleteAll}>

          Delete All
        </Button>}
      </ButtonGroup>}
      {loading && <Spinner size={'sm'}/>}
    </CardHeader>
    <CardBody>
      <div className="vx-collapse collapse-bordered">
        {breaches.map(row => {
          return (
            <Card key={`breach-${row.id}`}>
              <CardHeader id={`breach-${row.id}`}>
                <CardTitle className="lead collapse-title collapsed">
                  {row.title}

                </CardTitle>
                <div>
                  <BadgeStatus status={row.status} breach={row.status}/>
                  <ChevronDown size={15} className="collapse-icon"/>
                </div>
              </CardHeader>
              <UncontrolledCollapse toggler={`#breach-${row.id}`}>
                <CardBody>
                  {row.status === 'Rejected' &&
                  <Alert color="success">
                    <div className="alert-success p-25 pl-1"><AlertTriangle size={16}/> Rejected</div>
                    <p dangerouslySetInnerHTML={{__html: row.rejection_reason}} className="p-1"></p>
                  </Alert>}
                  <Table className="table-condensed" size={"sm"}>
                    <tbody>
                    <tr>
                      <th style={{minWidth: '200px'}}>Submission Deadline</th>
                      <td>{row.submission_deadline}</td>
                    </tr>
                    <tr>
                      <th>Comment</th>
                      <td>{row.comments}</td>
                    </tr>
                    <tr>
                      <th>Penalty</th>
                      <td>KES {row.penalty}</td>
                    </tr>
                    {/*<tr>*/}
                    {/*  <th>Additional Penalty</th>*/}
                    {/*  <td>KES {row.additional_penalty}</td>*/}
                    {/*</tr>*/}


                    </tbody>
                    <tfoot>

                    <tr>
                      <td className={'text-center'} colSpan={2}>
                        <ButtonGroup>
                          {Can('Obligation Breach Approval') && row.status === 'Pending' && <Button

                            size={'sm'}
                            onClick={() => _approve(row.id)}
                            color={'flat-danger'}>
                            <AlertTriangle size={14}/> Approve
                          </Button>}
                          {Can('Obligation Breach Approval') && row.status === 'Pending' &&
                          <Button onClick={() => {
                            setShowRejectionReason(true)
                            setRejection_reason(row.rejection_reason)
                            setBreach(row)

                          }
                          }
                                  size={'sm'}
                                  color={'flat-success'}>
                            <XCircle size={14}/> Reject
                          </Button>}
                          {Can('Obligation Breach Delete') && row.status === 'Rejected' && <Button
                            size={'sm'}

                            onClick={() => {
                              _delete(row.id);
                            }}
                            color={'flat-danger'}>
                            <Trash2 size={14}/> Delete
                          </Button>}
                        </ButtonGroup>
                      </td>
                    </tr>


                    </tfoot>
                  </Table>
                </CardBody>
              </UncontrolledCollapse>
            </Card>)
        })}
      </div>
    </CardBody>
    {obligation && <ObligationApproveBreach
      _get={props._get}
      toggle={_toggleModal}
      breach={breach}
      obligation={obligation}
      showBreachModal={showBreachModal}
    />}
    <SweetAlert
      title="Reasons for your rejection "
      show={showRejectionReason}
      placeHolder="Why?"
      onConfirm={() => _reject(breach.id,  rejection_reason)}
      showCancel
      cancelBtnBsStyle="primary"
      confirmBtnBsStyle={'danger'}
      confirmBtnText="Reject"
      cancelBtnText="Cancel"
      onCancel={() => {
        setRejection_reason(false)
        setShowRejectionReason(false)
        setBreach(false)
        //  setState({rejection_reasons: false, showRejectionReason: false, breach: false})
      }}
    >
      <Input
        type={'textarea'}
        placeholder={'Why'}
        rows={5}
        // onKeyPress={(e) => ((e.key === 'Enter' && e.ctrlKey) ? _reject() : null }
        onChange={e => setRejection_reason(e.target.value)}/>
    </SweetAlert>
    <SweetAlert
      title="Reason(s)"
      show={showRejectAllReason}
      placeHolder="Reasons for your rejection all pending breaches"
      onConfirm={_rejectAll}
      showCancel
      cancelBtnBsStyle="primary"
      confirmBtnBsStyle={'danger'}
      confirmBtnText="Reject"
      cancelBtnText="Cancel"
      onCancel={() => {
        setRejection_reason(false)
        setShowRejectionReason(false)
      }}
    >
      <Input
        type={'textarea'}
        placeholder={'Why'}
        rows={5}
        // onKeyPress={(e) => ((e.key === 'Enter' && e.ctrlKey) ? _reject() : null }
        onChange={e => setRejection_reason(e.target.value)}/>
    </SweetAlert>
  </Card>}</>)
}
export default ObligationBreaches;
