import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import {DownloadCloud,Edit, Filter} from "react-feather";
import Badge from "reactstrap/es/Badge";
import ObligationsList from "../compliance/components/ObligationsList";
import {useDispatch, useSelector} from "react-redux";
import {Pre} from "../../components/preFormat";
import {findEntity} from "../../redux/reducers/entity/entityReducer";
import classnames from "classnames";
import {getObligations} from "../../redux/actions/obligationActions";
import {Branding} from "./Branding";
import {Profile} from "./Profile";
import {Departments} from "../department/departments";
import {Clasification} from "./Clasification";
// import {Departments} from "../department/departments";

const Entity = props => {
  const dispatch = useDispatch();
  const {loading, entity} = useSelector(state => ({
    loading: state.Entity.loading,
    entity: state.Entity.details,
  }))
  const [active, toggle] = useState("obligations")

  const [dropdownOpen, toggleDropdown] = useState(false)
  const [branding, toggleBranding] = useState(false)
  const [classification, toggleClassification] = useState(false)
  const [profile, setProfile] = useState(false)
  const get = () => dispatch(findEntity())
  const getEntityObligations = () => dispatch((getObligations()))


  useEffect(() => {
    get()
  }, [])


  useEffect(() => {
    active === 'obligations' && getEntityObligations()
  }, [active])

  return <React.Fragment>
    <Card>
      <div style={{backgroundColor: 'white'}}>
        <div>
          <CardBody>
            <Row>
              <Col sm={3}>
                <Button onClick={() => toggleBranding(!branding)} className="position-absolute " size="sm"
                        color="primary"> <Edit size={12}/>
                  Change
                  Logo</Button>
                <img src={entity?.logo} className="img-fluid img-thumbnail" height={100} alt={"Logo"}/>

              </Col>
              <Col sm={6}>
                <div className="border-left border-left-2 pl-1">
                  <dl>
                    <dt>Services</dt>
                    <dd>{(entity?.services || []).map(row => <span key={row.id}><Badge
                      color={'success'}>{row.name}</Badge>{" "}</span>)}</dd>
                  </dl>
                  <dl>
                    <dt>Company Type</dt>
                    <dd><Badge color={'warning'}>{entity?.company_types}</Badge></dd>
                  </dl>
                  <dl>
                    <dd>{entity?.lolocation?.name}</dd>
                  </dl>
                </div>


              </Col>
              <Col sm={3}>
                {/*<Button block onClick={get} size="sm" color="info">Reload</Button>*/}
                {/*<Button block onClick={get} size="sm" color="info"><Image size={12}/> Edit Cover</Button>*/}
                <Button block onClick={() => setProfile(true)} size="sm" color="success"><Edit size={12}/> Edit Profile</Button>
                <Button block onClick={() => toggleClassification(!classification)} size="sm" color="primary"><Filter size={12}/> Edit Classification</Button>
                <Button block onClick={get} size="sm" color="warning"><DownloadCloud size={12}/> Import
                  Obligations</Button>
              </Col>

            </Row>
          </CardBody>
        </div>
      </div>
      <Nav tabs className="nav-justified mt-1">
        <NavItem>
          <NavLink className={classnames({active: active === "obligations"})} onClick={() => toggle("obligations")}>
            Obligations
          </NavLink>
        </NavItem>
        {/*<NavItem>*/}
        {/*  <NavLink className={classnames({active: active === "events"})} onClick={() => toggle("events")}>*/}
        {/*    Events*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        {/*<NavItem>*/}
        {/*  <NavLink className={classnames({active: active === "users"})} onClick={() => toggle("users")}>*/}
        {/*    Users*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        <NavItem>
          <NavLink className={classnames({active: active === "departments"})} onClick={() => toggle("departments")}>
            Departments
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className="py-50" activeTab={active}>
        <TabPane tabId="obligations">
          <ObligationsList authority callBack={getEntityObligations} obligations={entity?.obligations}/>
        </TabPane>
        <TabPane tabId="events"> </TabPane>
        <TabPane tabId="users"> </TabPane>
        <TabPane tabId="departments"><Departments /> </TabPane>
      </TabContent>

      <CardBody>
      </CardBody>
    </Card>



    <Branding isOpen={branding} toggle={() => toggleBranding(!branding)}/>
    <Profile isOpen={profile} toggle={() => setProfile(!profile)}/>
    <Clasification isOpen={classification} toggle={() => toggleClassification(!classification)}/>

  </React.Fragment>
}

export default Entity;
