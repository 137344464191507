import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {ErrorHelper, HandleInputChange, ValidateFormFields} from "../../utility/General";
import Select from "react-select";


export default class DepartmentFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.showForm,
      id: this.props.elementId,
      loading: false,
      form: false,
      fields: false,
      owners: [],
      errors: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isOpen !== this.props.isOpen) this.set(this.props.elementId);
  }

  componentDidMount() {
    this.set(this.props.elementId);
  }

  set = id => {
    if (!this.props.isOpen) return false;
    this.setState({loading: true})
    let url = 'app/global/department/set';
    let data = {
      id: this.props.elementId
    }
    axios.post(url, data)
      .then(res => this.setState({
        form: res.data,
        fields: res.data.fields,
        owners: res.data.owners,
        errors: res.data.errors
      }))
      .catch(this.error)
      .finally(() => this.setState({loading: false}))
  }

  post = () => {
    ValidateFormFields(this.state.fields, this.state.errors, ['title'])
      .then(res => this.setState({errors: res.errors, loading: true}));

    let url = "app/global/department/post";
    let data = this.state.fields;
    axios
      .post(url, data)
      .then(res => {
        this.toggleModal();
      }).catch(this.error)
      .finally(() => this.setState({loading: false}))
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
    this.props.callBack();
  }

  handleInputChange = (label, element, required) => {
    let results = HandleInputChange(label, element, required, this.state.fields, this.state.errors)
    let fields = results.fields;
    let errors = results.errors;
    this.setState({fields, errors})
  }


  error = (e) => {
    toast.error(e.status + "something went wrong");
    // this.props.callBack()
    // this.toggleModal();
  }

  render() {

    const {form, fields, errors, loading} = this.state;
    return (
      <>
        {(form) &&
          <Modal
            isOpen={this.props.isOpen}
            toggle={this.props.toggle}
            className="modal-centered modal-lg"
          >

            <Form onSubmit={(e) => {
              e.preventDefault();
              this.post();
            }}>
              <ModalHeader toggle={this.props.toggle}>
                Department
              </ModalHeader>
              <ModalBody>
                <FormGroup className="position-relative">
                  <Label> Title *</Label>
                  <Input type="text"
                         invalid={errors.title}
                         valid={errors.title === false}
                         defaultValue={fields.title}
                         onChange={e => this.setState(HandleInputChange('title', e, true, fields, errors))}
                         placeholder="Department Title"/>
                  <ErrorHelper show={errors.title}/>
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label> Head Of Department</Label>
                  <Select
                    defaultValue={fields?.head_of_department}
                    options={form.users}
                    onChange={e => HandleInputChange('head_of_department', e, true, fields, errors)}
                    className="React"
                    classNamePrefix="select"
                  />
                  <ErrorHelper show={errors.head_of_department}/>
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label> Department Users</Label>
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    defaultValue={fields?.department_users}
                    options={form.users}
                    onChange={e => HandleInputChange('department_users', e, true, fields, errors)}
                    className="React"
                    classNamePrefix="select"
                  />
                  <ErrorHelper show={errors.department_users}/>
                </FormGroup>


              </ModalBody>
              {!loading && <ModalFooter>
                <p className="float-left text-left"><small>Please fill in all the (*) fields</small></p>

                <Button color="warning" outline onClick={this.props.toggle}>
                  Cancel
                </Button>
                <Button color="success" outline type={'submit'}>
                  Submit
                </Button>

              </ModalFooter>}
            </Form>
          </Modal>}
      </>
    );
  }
}
