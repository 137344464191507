import React from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

class ConfirmDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultAlert: this.props.delete,
      confirmAlert: false,
      cancelAlert: false
    }
  }

  handleAlert = (state, value) => {
    this.setState({[state]: value})
  }

  componentDidMount = () => this.setState({delete: this.props.delete})


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.delete !== this.props.delete) this.setState({defaultAlert: this.props.delete})
  }

  render() {
    return (<>
        {/*//   <CardHeader>*/}
        {/*//     <CardTitle>Callback</CardTitle>*/}
        {/*//   </CardHeader>*/}
        {/*//   <CardBody>*/}
        {/*//     <Button className="mr-1 mb-1" color="primary" onClick={() =>
       this.handleAlert("defaultAlert", true)} outline>Callback</Button>*/}

        <SweetAlert title={`${this.props.actionTitle ? this.props.actionTitle : 'Delete!'} ${this.props.item} `}
                    warning
                    show={this.state.defaultAlert}
                    showCancel
                    reverseButtons
                    cancelBtnBsStyle="success"
                    confirmBtnBsStyle="danger"
                    confirmBtnText="Yes!"
                    cancelBtnText="No"
                    onConfirm={() => {
                      this.props.action();
                      this.handleAlert("basicAlert", false)
                      this.handleAlert("confirmAlert", true)
                    }}
                    onCancel={() => {
                      this.handleAlert("basicAlert", false)
                      this.handleAlert("cancelAlert", true)
                      if (this.props.callback) this.props.callback();

                    }}
        >
          <strong>{this.props.title}</strong>
        </SweetAlert>

        <SweetAlert success title={this.props.actionTitle ? this.props.actionTitle : 'Deleted!'}
                    confirmBtnBsStyle="success"
                    show={this.state.confirmAlert}
                    onConfirm={() => {
                      this.handleAlert("defaultAlert", false)
                      this.handleAlert("confirmAlert", false)
                      if (this.props.callback) this.props.callback();

                    }}
        >
          <p className="sweet-alert-text">{this.props.item} ({this.props.title})</p>
        </SweetAlert>

        <SweetAlert success title="Cancelled"
                    confirmBtnBsStyle="success"
                    show={this.state.cancelAlert}
                    onConfirm={() => {
                      this.handleAlert("defaultAlert", false)
                      this.handleAlert("cancelAlert", false)
                      if (this.props.callback) this.props.callback();
                    }}>
          <p className="sweet-alert-text">
            The {this.props.item} ({this.props.title}) was not deleted
          </p>
        </SweetAlert>

        {/*// </CardBody>*/}
      </>
    )
  }
}

export default ConfirmDelete
