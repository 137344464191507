import React, {useEffect, useState} from "react"
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row, UncontrolledTooltip} from "reactstrap"
import DataTable from "react-data-table-component"
import {Edit, PlayCircle, PlusCircle, RefreshCw, StopCircle, Trash} from "react-feather"
import {ToastContainer} from "react-toastify";
import ObligationFormModal from "./ObligationFormModal";
import {ButtonGroup} from "reactstrap/lib";
import {Link} from "react-router-dom";
import {BadgeStatus} from "../../../utility/General";
import moment from "moment";
import ConfirmDelete from "../../../utilities/ConfirmDelete";
import ObligationComplyForm from "../obligations/ObligationComplyForm";
import NumberFormat from "react-number-format";
import {Can} from "../../../utility/Can";
import {useDispatch, useSelector} from "react-redux";
import {activationObligation, deleteObligation, getObligations} from "../../../redux/actions/obligationActions";
import ObligationBreaches from "../obligations/ObligationBreaches";
import ObligationComplies from "../obligations/ObligationComplies";
import Select from "react-select";


const ObligationsList = (props) => {
  const dispatch = useDispatch();
  const {Obligations} = useSelector(state => state)
  const {loading} = Obligations.loading

  const [obligation, setObligation] = useState(props.obligations);

  const [filtered, setFiltered] = useState(props.obligations)

  const [comply, setComply] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [search, setSearch] = useState("")
  const [department, setDepartment] = useState()
  const [status, setStatus] = useState()
  const [departments, setDepartments] = useState()
  const [dueWithin, setDueWithin] = useState()


  const activation = (obligation) => dispatch(activationObligation(obligation.id))
  const columns = [
    {
      name: "Obligation",
      selector: "title",
      sortable: true,
      minWidth: "30%",
      cell: row => (
        <div className="">
          <div className="">
            <Link push to={`/compliance/obligation/${row.id}`}
                  title={row.title}
                  className="cursor-pointer">
              <strong
                className={row.status === 'Repealed' && 'text-muted'}>{row.title ? row.title : 'Untitled'}</strong>
            </Link>
            <div>
              <BadgeStatus status={row.status}/> {' '}
              {row.status === 'Active' && <><BadgeStatus status={row.submission_status}/> {' '} </>}
            </div>
          </div>
        </div>
      )
    },
    // {
    //   name: "Authority",
    //   selector: "authority",
    //   sortable: true,
    //   omit: true && props.authority,
    //   cell: row => <Link className="cursor-pointer"
    //                   onClick={() => props.history.push(`/compliance/authority/${row.authority_id}`)}>{row.authority}</Link>
    // },
    // {
    //   name: "Entity",
    //   selector: "entity_title",
    //   sortable: true,
    //   omit: true
    // },
    {
      name: "Department",
      selector: ({department}) => department?.title,
      sortable: true,
      // minWidth: "170px" ,
      // omit: true
      cell: row => row.department && <Link className="cursor-pointer"
                                           onClick={() => props.history.push(`/department/${row.department?.id}`)}>{row.department?.title}</Link>
    },
    {
      name: "Repeat",
      selector: "event",
      sortable: true,
      omit: props.event || true,
      cell: row => <div>
        <Link id={`${row.id}-event`} title={row.event_title} className="cursor-pointer">{row.event_title ? "Event Driven" : "Periodic"}
        </Link>

      </div>
    },
    {
      name: "Due Date",
      selector: "date",
      sortable: true,
      right: true,
      width: '180px',
      omit: props.submission_deadline,
      format: row => moment(row.submission_deadline).format('DD MMM YYYY')
    },
    {
      name: "Penalties (Ksh)",
      selector: "total_non_compliance_penalty",
      sortable: true,
      right: true,
      format: row => <NumberFormat value={row.total_non_compliance_penalty} decimalScale={2} displayType={'text'}
                                   thousandSeparator={true} prefix={''}/>
    },

    {
      name: "",
      selector: "id",
      sortable: false,
      right: true,
      omit: props.actions,
      minWidth: '250px',
      cell: row => (
        <ButtonGroup>
          {Can('Obligation Delete') && row.status === 'Inactive' && //DELETE OBLIGATION
            <Button size={'sm'}
                    onClick={() => {
                      setObligation(row)
                      setShowDelete(!showDelete)
                    }}
              // this.setState({obligation: row, delete: !this.state.delete})}
                    outline
                    color={'danger'}><Trash
              size={12}/></Button>}
          {(Can('Obligation Edit') && row.status === 'Inactive') && //EDIT OBLIGATION
            <Button size={'sm'}
                    onClick={() => this.setState({obligation: row, showForm: !showForm})} outline
                    color={'primary'}><Edit
              size={12}/></Button>}

          {Can('Obligation Activation') && (row.status === 'Inactive' || row.status === 'Active') && //ACTIVATE OBLIGATION
            <Button size={'sm'}
                    loading={loading}
                    onClick={() => activation(row)}
                    outline color={row.status === 'Active' ? 'warning' : 'success'}>
              {row.status === 'Active' && <> <PlayCircle size={12}/> Deactivate</>}
              {row.status === 'Inactive' && <> <StopCircle size={12}/> Activate</>}
            </Button>}


          {Can('Obligation Comply') && moment(row.submission_deadline).isBefore(new Date()) && row.status === 'Active' && // COMPLY TO AN OBLIGATION
            <Button
              size={'sm'}
              onClick={() => {
                setObligation(row)
                setComply(!comply)
              }}
              outline
              color={'success'}>Comply</Button>}


        </ButtonGroup>
      )

    }
  ];

  const toggleModal = () => setShowForm(!showForm)

  const formCallback = id => {
    if (id) props.history.push(`/compliance/obligation/${id}`)
    toggleModal();
  }
  //
  useEffect(() => {
    setFiltered(props.obligations)
    // if (search) setFiltered(props.obligations.filter(row => JSON.stringify(row).toLocaleLowerCase().includes(search.toLowerCase())))


  }, [props, department, status, search]);


  const SubHeader = () => {
    return <React.Fragment>
      {props.showFilters &&
        <Row className="w-100">

          <Col sm={3}>
            <Select className="mt-1" placeholder="Penalties Over (Ksh)" value={dueWithin} onChange={setDueWithin}
                    options={[
                      {label: "10,000 KES", value: "10,000 KES"},
                      {label: "50,000 KES", value: "50,000 KES"},
                      {label: "100,000 KES", value: "100,000 KES"},
                      {label: "200,000 KES", value: "200,000 KES"},
                      {label: "300,000 KES", value: "300,000 KES"},
                      {label: "400,000 KES", value: "400,000 KES"},
                      {label: "500,000 KES", value: "500,000 KES"},
                      {label: "1,000,000 KES", value: "1,000,000 KES"},
                    ]}/>
          </Col>

          <Col sm={3}>
            <Select className="mt-1" placeholder="Due Within" value={dueWithin} onChange={setDueWithin} options={[
              {label: "1 Week", value: "1 Week"},
              {label: "2 Week", value: "2 Week"},
              {label: "1 Month", value: "1 Month"},
              {label: "1 Quarter", value: "1 Quarter"},
              {label: "6 Months", value: "6 Months"},
              {label: "1 Year", value: "1 Year"},
            ]}/>
          </Col>
          <Col sm={3}>
            <Select className="mt-1" placeholder="Select Department"/>
          </Col>
          <Col sm={3}>
            <Select className="mt-1" placeholder=" Act Of Perliament"/>
          </Col>
          <Col sm={3}>
            <Select className="mt-1" placeholder="Select Owner"/>
          </Col>
          <Col sm={3}>
            <Select className="mt-1" placeholder="Select Authority"/>
          </Col>
          <Col sm={3}>
            <Select className="mt-1" placeholder="Select Register"/>
          </Col>
        </Row>
      }
    </React.Fragment>
  }

  const ExpandableTable = item => {

    return (item.data.breaches || []).length > 0 ? <ObligationBreaches
        callBack={() => dispatch(getObligations())}
        _get={() => dispatch(getObligations())}
        breaches={item.data.breaches}
        obligation={item.data}/>
      : <ObligationComplies callBack={() => dispatch(getObligations())}
                            _get={() => dispatch(getObligations())}
                            complies={item.data.complies}
                            obligation={item.data}/>
  }
  return (
    <Card>
      <ToastContainer/>
      {showForm && <ObligationFormModal
        {...props}
        complianceRegisterId={props.complianceRegisterId}
        authorityId={props.authorityId}
        modal={showForm}
        elementId={obligation ? obligation.id : 0}
        callBack={(res) => {
          props.callBack && props.callBack();
          formCallback(res)

        }}/>}

      <CardHeader className="mb-0">
        <CardTitle>{props.title ? props.title : 'Obligations'}</CardTitle>
        <ButtonGroup>
          <Button outline
                  color={'info'}
                  onClick={() => props.callBack ? props.callBack() : null}
                  size={'sm'}>
            <RefreshCw size={14}/>
          </Button>
          {Can('Obligation Create') && !props.hideCreateButton && <Button outline
                                                                          color={'success'}
                                                                          onClick={toggleModal} size={'sm'}>
            <PlusCircle size={14}/> Create Obligation
          </Button>}
        </ButtonGroup>

      </CardHeader>
      <CardBody className="mt-0">
        {(props.obligations || []).length > 0 && <DataTable
          expandableRowDisabled={(row) => (row.breaches || []).length === 0 && (row.complies || []).length === 0}
          expandableRows
          // subHeader={<SubHeader/>}
          // subHeaderComponent={<SubHeader/>}

          expandableRowExpanded={(row) => (row === currentRow)}
          expandOnRowClicked
          onRowClicked={(row) => setCurrentRow(row)}
          expandableRowsComponent={<ExpandableTable/>}
          onRowExpandToggled={(bool, row) => setCurrentRow(row)}
          className="dataTable-custom"
          data={(filtered || [])}
          columns={columns}
          // progressPending={loading && (props.obligations || []).length === 0}
          pagination
          // expandableRows={}
        />}

        {/*{loading && <DataTableLoading/>}*/}

      </CardBody>
      {obligation && showDelete &&
        <ConfirmDelete callback={() => setShowDelete(false)}
          // delete={() => this.state.delete(this.state.obligation.id)}
                       item={'Obligation'}
                       action={() => dispatch(deleteObligation(obligation.id, props.callBack))}
                       title={obligation.title}/>}

      {obligation && comply && <ObligationComplyForm
        obligation={obligation.id}
        complyID={0}
        modal={comply}
        toggle={() => {
          setObligation(false);
          setComply(false)
        }}
        callBack={() => props.callBack ? props.callBack() : null}
      />
      }
    </Card>

  )
}

export default ObligationsList

